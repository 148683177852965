import React from 'react';
import '../../../css/project.scss';
import { graphql, Link } from 'gatsby';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import HeadingDecorated from '../../../components/HeadingDecorated';
import Markdown from '../../../components/Markdown';
import downloadImg from '../../../images/download-large.png';
import arrowLeftImg from '../../../images/arrow-left.png';
import arrowRightImg from '../../../images/arrow-right.png';

export const query = graphql`
  query ($id: String!) {
    project: strapiProject(id: { eq: $id }) {
      id
      avatar {
        localFile {
          url
        }
      }
      localizations {
        data {
          attributes {
            name
            description
          }
        }
      }
    }
    files: allStrapiProjectFile(
      filter: { project: { id: { eq: $id } }, locale: { eq: "en" } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        name
        description
        file {
          localFile {
            url
          }
        }
        thumbnail {
          localFile {
            url
          }
        }
      }
    }
    edges: allStrapiProject(
      sort: { fields: position, order: ASC }
      filter: {
        localizations: {
          data: { elemMatch: { attributes: { locale: { eq: "en" } } } }
        }
      }
    ) {
      edges {
        previous {
          id
          localizations {
            data {
              attributes {
                name
              }
            }
          }
        }
        node {
          id
        }
        next {
          id
          localizations {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

const ProjectPage = ({
  data: {
    project,
    files: { nodes: files },
    edges: { edges },
  },
}) => {
  const currentEdge = React.useMemo(
    () => edges.find((edge) => edge.node.id === project.id),
    [project, edges]
  );
  const localizedProjectData = project.localizations.data[0].attributes;

  return (
    <Layout locale="en">
      <Seo title={localizedProjectData.name} />
      <div className="project">
        <nav className="project__breadcrumb">
          <Link to="/en/our-projects/">Our projects</Link>
        </nav>

        <HeadingDecorated className="project__heading">
          {localizedProjectData.name}
        </HeadingDecorated>

        <section className="project__about">
          <img
            src={project.avatar.localFile.url}
            className="accent-shadow-responsive project__avatar"
            alt={localizedProjectData.name}
          />
          <h3 className="project__name">About the project</h3>
          <Markdown className="project__description">
            {localizedProjectData.description}
          </Markdown>
        </section>
        {files.length > 0 && (
          <section className="project__downloads">
            <h3>Download documents</h3>
            <div className="project__files">
              {files.map((file) => (
                <figure className="project__file">
                  <img
                    src={file.thumbnail.localFile.url}
                    alt="thumbnail"
                    className="project__file-thumbnail"
                  />
                  <figcaption>
                    <h4>{file.name}</h4>
                    <span>{file.description}</span>
                    <a href={file.file.localFile.url}>
                      <img src={downloadImg} alt="download" />
                      Download
                    </a>
                  </figcaption>
                </figure>
              ))}
            </div>
          </section>
        )}
        <nav class="project__footer-nav">
          {currentEdge.previous === null ? (
            <Link to="/en/our-projects/">
              <img src={arrowLeftImg} alt="&larr;" />
              Our projects
            </Link>
          ) : (
            <Link to={`/en/our-projects/${currentEdge.previous.id}`}>
              <img src={arrowLeftImg} alt="&larr;" />
              {currentEdge.previous.name}
            </Link>
          )}

          {currentEdge.next && (
            <div className="project__footer-nav-divider"></div>
          )}

          {currentEdge.next !== null && (
            <Link to={`/en/our-projects/${currentEdge.next.id}`}>
              {currentEdge.next.name}
              <img src={arrowRightImg} alt="&rarr;" />
            </Link>
          )}
        </nav>
      </div>
    </Layout>
  );
};

export default ProjectPage;
